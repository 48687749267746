import { Component, OnInit } from '@angular/core';
import { IonApp, IonRouterOutlet } from '@ionic/angular/standalone';
import { AuthService } from './auth.service';
import { Router } from '@angular/router';
import { DataStoreService } from './data-store.service';
import { GlobalErrorHandler } from './globalErrorHandler';
import { RestApiService } from './rest-api.service';
import { TokenInfo } from './models';
import { SwUpdate } from '@angular/service-worker';
import { ToastController } from '@ionic/angular';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { HttpClient } from '@angular/common/http';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';

// AoT requires an exported function for factories
export function HttpLoaderFactory(httpClient: HttpClient) {
  return new TranslateHttpLoader(httpClient);
}

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  standalone: true,
  imports: [IonApp, IonRouterOutlet, TranslateModule]
})
export class AppComponent implements OnInit {

  constructor(private authService: AuthService, private router: Router, private store: DataStoreService,
    private apiService: RestApiService, private errorHandler: GlobalErrorHandler, private swUpdate: SwUpdate, private toastCtrl: ToastController,
    private translate: TranslateService
  ) {

    let selectedLanguage = window.localStorage.getItem("dhigSelectedLanguage");
    let selectedLanguageCode = 'en';
    if (selectedLanguage && selectedLanguage !== "") {
      selectedLanguageCode = selectedLanguage.toString();
    }

    this.translate.setDefaultLang(selectedLanguageCode);
    translate.addLangs(['en','de', 'sk', 'cs', 'hu', 'pl', 'sr']);
    if (this.swUpdate.isEnabled) {
      this.swUpdate.versionUpdates.subscribe(() => {
        this.toastCtrl.create({
          message: "App updated",
          duration: 1500,
          position: 'bottom'
        }).then(toast => {
          toast.present();
          window.location.reload();
        });
      });
    }
  }

  ngOnInit(): void {
    this.authService.isAuthenticated.subscribe((isAuthenticated) => {
      if (!isAuthenticated && this.router.url !== '/auth' && !window.location.pathname.startsWith('/passwordcreate')) {
        this.router.navigate(['/auth']);
      }
      if (isAuthenticated && !this.store.person?.id) {
        let token = localStorage.getItem('token');
        if (token) {
          let info = this.authService.readToken(token);
          this.store.tokenInfo = info ?? {} as TokenInfo;
          this.apiService.get(`Persons/Single?personId=${this.store.tokenInfo.PersonId}`).then((person: any) => {
            this.store.person = person;
          });
        }
        else {
          this.errorHandler.handleError(new Error("Token not found"));
          this.router.navigate(['/auth']);
        }
      }
    });
  }

  checkForUpdate() {
    this.swUpdate.checkForUpdate();
  }
}
